import React, { useMemo } from "react";
import { StaticImage, GatsbyImage } from "gatsby-plugin-image";
import { graphql } from "gatsby";
import ReactPlayer from "react-player";
import Layout, { ParallaxContext } from "@/components/Layout";
import Seo from "@/components/Seo";
import Header, { NavigationItem } from "@/components/Header";
import ArticleOverview from "@/components/ArticleOverview";
import Footer, { LinkItem } from "@/components/Footer";
import Media from "@/components/Media";
import ProductOverview from "@/components/ProductOverview";
import { range } from "@/utils/interpolate";
import ScrollAndFixed from "@/components/ScrollAndFixed";
import ProductReel, { mapDataToProducts } from "@/components/ProductReel";
import notEmpty from "@/utils/not-empty";
import { HomepageQuery } from "../../graphql-types";
import { ProductFragment } from "@/fragments/product";
import video from "../videos/home.mp4";
import "@/styles/video.css";

interface Props {
  data: HomepageQuery;
}

const IndexPage = ({ data }: Props) => {
  const {
    nodes: [firstProduct, secondProduct],
  } = data.products;
  const {
    nodes: [collection],
  } = data.collectionProducts;
  const { headerLinks, footerLinks } = data.global || {};
  const { videoPoster } = data;
  const reelProducts = useMemo(() => {
    const filteredProducts = collection?.products?.filter<ProductFragment>(notEmpty) || [];

    return mapDataToProducts(filteredProducts);
  }, [collection]);

  return (
    <Layout
      scroll="default"
      header={
        <Header
          navigation={(headerLinks as NavigationItem[]) || undefined}
          utilities={
            [
              // {
              //   label: (
              //     <div className="relative">
              //       <ShoppingBag style={{ fill: "none" }} />
              //       <span className="absolute left-1/2 bottom-1/2">
              //         <div className="inline-flex items-center justify-center w-5 h-5 text-xs font-bold text-white rounded-full leading-4 bg-brick-red">
              //           6
              //         </div>
              //       </span>
              //     </div>
              //   ),
              // },
            ]
          }
        />
      }
    >
      <Seo title="Saman Loira" />
      <Layout.Section span={1.5}>
        <ParallaxContext.Consumer>
          {value => {
            return (
              <div style={{ height: "150vh", width: "100%", margin: range([0, 2], [0, 40], value) }}>
                <ReactPlayer
                  className="video"
                  url={video}
                  width="100%"
                  height="100%"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                  muted
                  playing
                  loop
                  playsinline
                  config={
                    videoPoster?.publicURL
                      ? {
                          file: {
                            attributes: {
                              poster: videoPoster.publicURL,
                            },
                          },
                        }
                      : {}
                  }
                />
                {/* <StaticImage placeholder="blurred" src="../images/home.jpg" alt="test" style={{ height: "100%" }} /> */}
              </div>
            );
          }}
        </ParallaxContext.Consumer>
      </Layout.Section>
      <Layout.Section>
        <ArticleOverview
          title="Neapolitan Demi-Couture"
          subtitle="About my Fairy tale"
          images={[
            <StaticImage
              placeholder="blurred"
              key="one"
              src="../images/content/home_one.png"
              alt="about us, first picture"
              objectFit="cover"
            />,
            <StaticImage
              placeholder="blurred"
              key="two"
              src="../images/content/home_two.png"
              alt="about us, second picture"
              objectFit="cover"
            />,
          ]}
          link="/about-us"
        >
          <p>The materiality of spirit and the spirituality of matter.</p>
          <p>
            The dreamlike inspiration of Saman Loira who puts Nature at the center together with the search for organic
            raw materials joins the beneficial properties of embroidered mineral stones, creating a synergy for clothes
            that are feelings and emotions.
          </p>
          <p>
            Combining the wonderful with the everyday, street style with haute couture, for him and for her in a game of
            reflections where men are capable of feelings, emancipating their feminine side and women to govern with
            determination.
          </p>
        </ArticleOverview>
      </Layout.Section>
      <Layout.Section theme="alabaster">
        <Media image={<GatsbyImage image={firstProduct.images?.[0]?.gatsbyImageData} alt={firstProduct.title || ""} />}>
          <ProductOverview
            title={firstProduct.title}
            link={firstProduct?.variants?.[0]?.slug || "/"}
            description={firstProduct?.shortDescription}
            image={<GatsbyImage image={firstProduct.images?.[1]?.gatsbyImageData} alt={firstProduct.title || ""} />}
            isOutOfStock={
              !firstProduct?.variants?.some(variant => variant?.inventoryQuantity && variant.inventoryQuantity > 0)
            }
          />
        </Media>
      </Layout.Section>
      <ProductReel products={reelProducts} />
      <Layout.Section theme="alabaster">
        <Media
          layout="reverse"
          image={<GatsbyImage image={secondProduct?.images?.[0]?.gatsbyImageData} alt={secondProduct.title || ""} />}
        >
          <ProductOverview
            title={secondProduct.title}
            link={secondProduct.variants?.[0]?.slug || "/"}
            description={secondProduct.shortDescription}
            image={<GatsbyImage image={secondProduct?.images?.[1]?.gatsbyImageData} alt={secondProduct.title || ""} />}
            isOutOfStock={
              !secondProduct?.variants?.some(variant => variant?.inventoryQuantity && variant.inventoryQuantity > 0)
            }
          />
        </Media>
      </Layout.Section>

      <ScrollAndFixed>
        <StaticImage placeholder="blurred" src="../images/content/home_footer.png" alt="Shooting Spring / Summer" />
      </ScrollAndFixed>

      {footerLinks && <Footer links={footerLinks as LinkItem[]} />}
    </Layout>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query Homepage {
    products: allShopifyProduct(limit: 2, filter: { title: { in: ["Chiffon Silk Top", "Black Tulle Dress"] } }) {
      nodes {
        ...Product
      }
    }
    collectionProducts: allShopifyCollection(limit: 1, filter: { title: { in: ["Homepage"] } }) {
      nodes {
        products {
          ...Product
        }
      }
    }
    global: dataJson {
      ...Global
    }
    videoPoster: file(name: { eq: "home-poster" }) {
      publicURL
    }
  }
`;
