import { range } from "@/utils/interpolate";
import React, { useContext } from "react";
import Heading, { Container } from "@/components/Heading";
import { ParallaxContext, SectionContext } from "@/components/Layout";
import Link from "@/components/Link";
import MicroCopy from "../MicroCopy";

export interface Props {
  title: React.ReactNode;
  children?: React.ReactNode;
  subtitle?: React.ReactNode;
  images: [React.ReactNode, React.ReactNode?];
  link?: string;
}

const ArticleOverview = ({ title, children, subtitle, images, link }: Props) => {
  const value = useContext(ParallaxContext);
  const offset = useContext(SectionContext);
  const imagePosition = range([offset - 0.5, offset + 0.5], [60, -10], value);

  return (
    <div className="container">
      <div className="grid grid-cols-none gap-5 md:grid-cols-12">
        <div className="flex flex-col justify-center col-span-6">
          <Container>
            <Heading className="text-cod-gray" subtitle={subtitle}>
              {title}
            </Heading>
            {children ? <div className="mt-4">{children}</div> : null}
            {link && (
              <div className="mt-8">
                <Link to={link} className="text-black link link--inline">
                  <MicroCopy path="global.read-more">Read more</MicroCopy>
                  <span className="sr-only">about {title}</span>
                </Link>
              </div>
            )}
          </Container>
        </div>
        <div className="col-span-6">
          <div className="flex max-h-full">
            {React.Children.map(images, (image, index) => {
              const multiplier = index === 0 ? 1 : -1;

              return (
                <div
                  style={{
                    transform: images.length === 1 ? undefined : `translateY(${imagePosition * multiplier}px)`,
                  }}
                  className="flex-1 px-2"
                >
                  {image}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleOverview;
